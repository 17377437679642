.main_container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10rem 0 5rem 0;
}
.main_container::before {
    content: "";
    position: absolute;
    min-height: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background: url(../../Images/SpeakersBg.svg);
    background-size: cover;
    background-repeat: repeat;
    z-index: -1;
}

.banner_bg {
    background-color: white;
    height: 10vh;
}

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0rem 0 10rem 0;
    gap: 5rem;
    align-items: center;
}

.container > h1 {
    text-align: center;
    font-size: 3rem;
    color: #111;
    font-weight: 600;
    font-family: "mudclaw";
}

.container::before {
    content: "";
    top: 0;
    bottom: 0;
    position: absolute;
    /* background: url(../../Images/SpeakersBg.png) no-repeat; */
    height: 100%;
    width: 100%;
    z-index: -1;
}

.container_row {
    display: flex;
    gap: 2rem;
    padding: 0 2rem;
}

.top_part {
    display: flex;
    height: 28px;
    background-color: white;
    align-items: center;
    gap: 1rem;
    padding-left: 1rem;
}

.top_part_circles {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: black;
}

/* Speaker seection Banner */

#rssBlock {
    left: 0px;
    top: 0;
    height: 80px;
    background: black;
    position: absolute;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 2rem;
    /* transform: translateY(-240px); */
}

/*remove p*/
.cnnContents {
    width: 100vw;
    /* padding-top: 20px; */
    margin: 0 auto;
    font-size: 30px;
    white-space: nowrap;
    text-transform: uppercase;
}
.cnnContents span {
    font-family: "Mudclaw", "sans-serif";
    font-weight: 300;
    color: white;
}

.marqueeStyle {
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 2rem;
    /* Apply animation to this element */
    -webkit-animation: scrolling-left 15s linear infinite;
    animation: scrolling-left 15s linear infinite;
}

/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-left {
    to {
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
    }
}

@media only screen and (max-width: 930px) {
    .container_row {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        padding: 0rem 0rem;
    }
}
@media only screen and (max-width: 660px) {
    .container_row_2 {
        gap: 2rem;

        flex-direction: column;
        align-items: center;
        padding: 0rem 0rem;
    }
    .container {
        padding: 6rem 0 3rem 0;
        gap: 2rem;
    }
}
