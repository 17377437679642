.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4em 0 3rem 0;
    gap: 4rem;
}
.container > img {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.container h1 {
    font-size: 4.5rem;
    text-align: center;
    font-weight: 600;
    font-family: "mudclaw";
}

.container button {
    width: fit-content;
    padding: 1em 2em;
    box-shadow: 6px 6px 0px #111;
    border-radius: 0;
    background: var(--secondary-color);
    outline: 3px solid black;
    border: 0;
    font-weight: 800;
    transition: all 400ms ease;
}

.container button:hover {
    background-color: black;
    color: white;
    box-shadow: 4px 4px 0px #e1ffd7;
}

.boxes_container {
    display: flex;
    gap: 4rem;
}

.box_sub_container {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
}

.box_sub_container > h1 {
    font-size: 3rem;
}

.box1,
.box2,
.box3 {
    width: 240px;
    height: 240px;
    background-color: var(--event-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.box1 > h1,
.box2 > h1,
.box3 > h1 {
    font-size: 8rem;
}

.box1 {
    box-shadow: -8px -8px 0px black;
}
.box2 {
    outline: 8px solid black;
}
.box3 {
    box-shadow: 8px 8px 0px black;
}

@media only screen and (max-width: 1100px) {
    .boxes_container {
        display: flex;
        flex-direction: column;
        gap: 4rem;
    }
}

@media only screen and (max-width: 800px) {
    .container > h1 {
        font-size: 4em;
    }
}
@media only screen and (max-width: 500px) {
    .container > h1 {
        font-size: 3em;
    }
}
