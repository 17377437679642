.container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 8rem 3rem;
}

.container > img {
    width: 40%;
}

.title_heading_container {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 2em;
}

.title_heading_container > h1 {
    font-weight: 600;
    font-family: "mudclaw";
}

.title_heading_container > p {
    line-height: 221%;
    font-size: 1.25em;
}

@media only screen and (max-width: 1100px) {
    .container {
        display: flex;
        flex-direction: column;
        gap: 4rem;
    }
    .title_heading_container {
        text-align: center;
        width: 90%;
        gap: 2em;
    }
    .container > img {
        width: 95%;
    }
}
@media only screen and (max-width: 500px) {
    .title_heading_container {
        text-align: center;
        width: 98%;
        gap: 2em;
    }
}
@media only screen and (max-width: 700px) {
    .title_heading_container {
        width: 98%;
    }
}
@media only screen and (max-width: 380px) {
    .title_heading_container > p {
        line-height: 160%;
        font-size: 0.75em;
    }
}
